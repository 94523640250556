import React, {useState} from "react";
import {Avatar, Box, Flex, HStack, Image, Link, Text} from "@chakra-ui/react";
import {FaArrowDown, FaArrowUp} from "react-icons/all";
let dayjs = require("dayjs");
let relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Post = ({ post }) => {
	let { id, username, title, content, type, date, votes } = post;

	const [voteCount, setVoteCount] = useState(votes);
	const handleVote = async (increase) => {
		fetch(`https://hiring-api.xela.workers.dev/${id}/${increase ? "upvote" : "downvote"}`, {
			method: "PUT"
		}).then(async (response) => {
			if (response.ok)
				setVoteCount(voteCount + (increase ? 1 : -1));
		});
	};

	return (
		<Flex
			borderWidth="2px" borderStyle="solid" borderColor="cyan.500"
			borderRadius="16" p="3" width="100%"
			backgroundColor="gray.800"
		>
			<Avatar />
			<Box
				ml="4" textAlign="left" backgroundColor=""
				color="white" position="relative" width="100%"
			>
				<Box width="100%" display="flex" justifyContent="space-between" mb="2">
					<Text fontWeight="bold">
						{title}
					</Text>
					<HStack>
						<Box cursor="pointer" onClick={() => handleVote(true)}><FaArrowUp /></Box>
						<Text ml="2" mr="2" my="0" color={
							voteCount > 0 ? "#34e361" : voteCount < 0 ? "red.500" : "cyan.500"
						}>{voteCount}</Text>
						<Box cursor="pointer" onClick={() => handleVote(false)}><FaArrowDown /></Box>
					</HStack>
				</Box>
				{type === "text" ? (
					<Text fontSize="md">{content}</Text>
				) : null}
				{type === "url" ? (
					<Link color="cyan.500" fontSize="md" href={content} target="_blank">
						{content}
					</Link>
				) : null}
				{type === "image" ? (
					<Image mt="3" width="100%" src={content} alt="content" borderRadius="4" />
				) : null}
				{type === "video" ? (
					<video controls src={content} style={{
						marginTop: "0.75rem",
						borderRadius: "1rem",
						maxHeight: "50vh"
					}} />
				) : null}
				<Text fontSize="sm" fontWeight="bold" mt="3" wordBreak="break-all">
					By {username}, {dayjs(date).fromNow()}
				</Text>
			</Box>
		</Flex>
	);
};

export default Post;
