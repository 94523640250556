import React, {useEffect, useState} from "react";
import {
	ChakraProvider,
	Box,
	VStack,
	Grid,
	Heading,
	Text,
	theme,
	Input,
	Container,
	Textarea,
	Button,
	Select,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription, CloseButton
} from "@chakra-ui/react";
import Post from "./components/Post";

function App() {
	const [posts, setPosts] = useState([]);
	const [showError, setShowError] = useState(false);
	const [errorDescription, setErrorDescription] = useState(null);
	const [showSuccess, setShowSuccess] = useState(false);
	const [postLoading, setPostLoading] = useState(false);
	const [successDescription, setSuccessDescription] = useState(null);

	useEffect(() => {
		async function fetchData() {
			let response = await fetch("https://hiring-api.xela.workers.dev/posts");
			let json = await response.json();

			if (json)
				setPosts(json);
		}
		fetchData();
	}, []);

	const handlePost = async event => {
		event.preventDefault();

		if (!event.target.username.value) {
			setErrorDescription("You must provide a username!");
			setShowError(true);
			return;
		} else if (!event.target.title.value) {
			setErrorDescription("You must provide a title!");
			setShowError(true);
			return;
		} else if (!event.target.type.value) {
			setErrorDescription("You must provide a content type!");
			setShowError(true);
			return;
		} else if (!event.target.content.value) {
			setErrorDescription("You must provide some content!");
			setShowError(true);
			return;
		}

		setPostLoading(true);

		fetch("https://hiring-api.xela.workers.dev/posts", {
			method: "POST",
			body: JSON.stringify({
				username: event.target.username.value,
				title: event.target.title.value,
				type: event.target.type.value,
				content: event.target.content.value
			}),
			credentials: "include"
		}).then(async (response) => {
			let text = await response.text();

			if (response.ok) {
				setSuccessDescription("Your post was sent successfully!");
				setShowSuccess(true);
			} else {
				setErrorDescription(text);
				setShowError(true);
			}

			setPostLoading(false);
		}).catch(() => {
			setErrorDescription("An error occurred whilst sending your request!");
			setShowError(true);
			setPostLoading(false);
		});
	};

	return (
		<ChakraProvider theme={theme}>
			<Box textAlign="center" fontSize="xl" minHeight="100vh" backgroundColor="gray.900">
				<Grid p={3}>
					<Container>
						<Box mb="4">
							<Heading color="white">Stratus 🌩</Heading>
							<Text color="white" fontWeight="bold">The future of social media</Text>
						</Box>
						<Box mb="5">
							<form onSubmit={handlePost}>
								<Input
									focusBorderColor="cyan.100"
									placeholder="Username"
									borderColor="cyan.500"
									borderWidth="2px"
									color="white"
									mb="3"
									name="username"
								/>
								<Input
									focusBorderColor="cyan.100"
									placeholder="Title"
									borderColor="cyan.500"
									borderWidth="2px"
									color="white"
									mb="3"
									name="title"
								/>
								<Select
									focusBorderColor="cyan.100"
									borderColor="cyan.500"
									borderWidth="2px"
									color="white"
									mb="3"
									name="type"
								>
									<option style={{ background: "#171923" }} value="text">Text</option>
									<option style={{ background: "#171923" }} value="url">Hyperlink (URL)</option>
									<option style={{ background: "#171923" }} value="image">Image</option>
									<option style={{ background: "#171923" }} value="video">Video</option>
								</Select>
								<Textarea
									placeholder="Content"
									borderColor="cyan.500"
									borderWidth="2px"
									focusBorderColor="cyan.100"
									color="white"
									name="content"
								/>
								<Alert
									status="success" display={showSuccess ? "flex" : "none"} fontSize="75%"
									mt="3" borderRadius="4" backgroundColor="green.700" color="white"
								>
									<AlertIcon color="green.300" />
									<AlertTitle mr={2}>Success!</AlertTitle>
									<AlertDescription>{successDescription}</AlertDescription>
									<CloseButton
										position="absolute" right="8px" top="8px"
										onClick={() => setShowSuccess(false)}
									/>
								</Alert>
								<Alert
									status="error" display={showError ? "flex" : "none"} fontSize="75%"
									mt="3" borderRadius="4" backgroundColor="red.700" color="white"
								>
									<AlertIcon color="red.300" />
									<AlertTitle mr={2}>Error!</AlertTitle>
									<AlertDescription>{errorDescription}</AlertDescription>
									<CloseButton
										position="absolute" right="8px" top="8px"
										onClick={() => setShowError(false)}
									/>
								</Alert>
								<Button
									width="100%" mt="3" colorScheme="cyan"
									type="submit" isLoading={postLoading}
								>
									Post!
								</Button>
							</form>
						</Box>
						<Heading mb="4" color="white">Posts</Heading>
						<VStack spacing={8} mb="4">
							{posts.sort((a, b) => {
								if (a.votes > b.votes) return -1;
								if (b.votes > a.votes) return 1;
								if (a.date > b.date) return -1;
								if (b.date > a.date) return 1;

								return 0;
							}).map(p => {
								return (
									<Post post={p} />
								);
							})}
						</VStack>
					</Container>
				</Grid>
			</Box>
		</ChakraProvider>
	);
}

export default App;
